import { Reward } from '@/models/profile/MyVoucherDetail.model';
import React from 'react';
import styles from '../../styles/coupon.module.css';
import Image from 'next/image';
import { Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useAppDispatch } from '@/store/store';
import {
  get1010CouponQuery,
  getTypeCouponQuery,
  setIsClick,
} from '@/store/slices/get1010CouponSlice';

interface Props {
  data: Reward;
}

export default function CouponCard({ data }: Props) {
  const { t, i18n } = useTranslation('');
  const dispatch = useAppDispatch();
  const getCoupon = (id: string) => {
    dispatch(setIsClick(true));
    dispatch(
      get1010CouponQuery({
        lang: i18n.language,
        rewardid: id,
      }),
    );
  };
  const getTypeCoupon = (id: string) => {
    dispatch(setIsClick(true));
    dispatch(
      getTypeCouponQuery({
        lang: i18n.language,
        campaignId: id,
        type: 'ncpn',
      }),
    );
  };

  return (
    <div className={`${styles.couponwrap} bg-primaryPink`}>
      <div className="p-[16px] xl:p-[23px] my-auto">
        <div className="w-[88px] h-[88px]  xl:w-[110px] xl:h-[110px]">
          <Image
            // src={data.image ? data.image : '/images/svg/no-img.svg'}
            src={
              data.image
                ? data.couponSource === 'camp'
                  ? data.image
                  : data.couponSource === 'omni'
                  ? `${process.env.NEXT_PUBLIC_BASE_URL_WEB}/${data.image}`
                  : '/images/svg/no-img.svg'
                : '/images/svg/no-img.svg'
            }
            alt="logo"
            className="rounded-lg"
            width={110}
            height={110}
          />
        </div>
      </div>
      <div className="-mt-1.5">
        {[...Array(12)].map((_, index) => (
          <div
            key={index}
            className="w-[8px] h-[8px] bg-background rounded-full my-1"
          ></div>
        ))}
      </div>
      <div className="px-[10px] pb-[12px] text-primary w-full">
        <span className="">
          {data.couponSource === 'camp' ? (
            <>
              <span className="pr-2 text-2xl font-bold whitespace-nowrap">
                {t('usevoucher:voucher')}
              </span>
              <span className="f-kitti75 couponprice">
                <span className="text-md lg:text-xl pr-1 font-bold">
                  {data.couponUnit === '฿' ? data.couponUnit : ''}
                </span>
                <span className="text-2xl lg:text-3xl font-bold">
                  {data.couponAmount || ''}
                </span>
                <span className="text-2xl lg:text-3xl">
                  {data.couponUnit === '%' ? data.couponUnit : '.-'}
                </span>
              </span>
            </>
          ) : data.couponSource === 'omni' ? (
            <span className="text-2xl font-bold line-clamp-1">{data.name}</span>
          ) : null}
        </span>
        <div className="leading-[0.5rem] lg:leading-3 h-[1.5rem] lg:h-[2rem] pr-2 text-grayDark3 text-[15px] lg:text-[18px] line-clamp-3">
          {data.displayName}
        </div>
        <div className="flex leading-3">
          <div>
            <div
              className="leading-[0.5rem] lg:leading-3 mt-[0.20rem] lg:mt-[0.25rem] text-grayDark3 text-[15px] lg:text-[18px]"
              // style={{ lineHeight: '18px' }}
            >
              {data.id}
            </div>
            <div
              className={`leading-[0.5rem] lg:leading-3 ${styles.date} text-[15px] lg:text-[18px] text-grayDark3 mt-[0.20rem] lg:mt-[0.25rem] line-clamp-1`}
              // style={{ lineHeight: '18px' }}
            >
              <span className="me-1">
                {data.couponSource === 'omni'
                  ? t('usevoucher:distributiondate')
                  : t('usevoucher:availabledate')}
              </span>
              {data.couponSource === 'camp' ? (
                <>
                  {data.startDateShow} {data.startMonthShow}
                  {data.startYearShow !== data.endYearShow
                    ? data.startYearShow
                      ? (data.startYearShow + 543).toString().slice(-2)
                      : ''
                    : ''}
                  -{data.endDateShow} {data.endMonthShow}
                  {data.endYearShow
                    ? (data.endYearShow + 543).toString().slice(-2)
                    : ''}
                </>
              ) : data.couponSource === 'omni' ? (
                <>
                  {data.startDateOmniCoupon
                    ? `${data.startDateOmniCoupon}`
                    : '-'}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="ml-auto mt-auto">
            <Button
              variant="outlined"
              sx={{
                color: 'white',
                backgroundColor: `${
                  data.isDisabledOmniCoupon ? '#B4B4B4' : '#B8292F'
                }`,
                borderColor: '#B8292F',
                fontSize: { lg: '21px', xs: '18px' },
                minWidth: 0,
                padding: {
                  xs: '7px 16px',
                  lg: '5.5px 21px',
                },
                fontWeight: 700,
                borderRadius: '6px',
                lineHeight: '21px !important',
                '&:hover': {
                  background: '#B8292F',
                },
                '&:disabled': {
                  backgroundColor: '#B4B4B4 !important',
                  color: '#EBEBEB !important',
                  border: '1px solid #B4B4B4 !important',
                },
              }}
              disabled={data.isDisabledOmniCoupon}
              onClick={() => {
                if (data.couponSource === 'camp') {
                  getCoupon(`${data.id}`);
                } else if (data.couponSource === 'omni') {
                  getTypeCoupon(`${data.id}`);
                }
              }}
            >
              {t('usevoucher:getCoupon')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
