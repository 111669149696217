import { CmsType, Content } from '@/models/CMS.model';
import React, { ReactNode, useEffect } from 'react';
import SeoCMS from './SeoCMS';
import HeroBanner from '../homepage/HeroMenu/HeroBanner';
import ArticleCat from './ArticleCat';
import FlashSale from '../homepage/FlashSale';
import BundleImage from '../bundle/BundleImage';
import SkuProductskus from '../staticPage/SkuProductskus';
import BundlePage from '../bundle/BundlePage';
import UseCoupon from '../staticPage/UseCoupon';
import Default from '../staticPage/Default';
import Coupon from '../staticPage/Coupon';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@/store/store';
import { useSelector } from 'react-redux';
import {
  clearResultGet1010,
  get1010CouponResultsSelector,
  isLoading1010CouponSelector,
  get1010CouponQuery,
  getTypeCouponQuery,
  getTypeCouponResultsSelector,
  isClickGet1010couponSelector,
  setIsClick,
} from '@/store/slices/get1010CouponSlice';
import { useTranslation } from 'next-i18next';
import * as showDialog from '@/utils/showDialog';
import { getRedirectQuery } from '@/utils/theone';
import Loading from '../Layout/handler/Loading';
import DefaultContent from '../staticPage/DefaultContent';

interface Props {
  children: ReactNode;
  contents?: Content[];
  section1?: string;
}

export default function CmsWrapper({ children, contents, section1 }: Props) {
  let newContents: Content[] = [];
  let sortContents: Content[] = [];
  newContents = contents ? [...contents] : [];
  if (contents && contents.length > 0) {
    let hasMain = contents?.findIndex(
      (item) => item.type === CmsType.isCategoryPage,
    );
    if (hasMain < 0) {
      let hasInsertCategory: boolean = false;
      let articleIndex = newContents?.findIndex(
        (item) => item.type == 'article_cat',
      );
      if (articleIndex !== -1) {
        hasInsertCategory = true;
        const setRemoveObject = newContents.splice(articleIndex, 1)[0];
        sortContents = [...sortContents, setRemoveObject];
      }
      let seoIndex = newContents?.findIndex(
        (item) => item.type === CmsType.seoLandingPage,
      );
      if (seoIndex !== -1) {
        hasInsertCategory = true;
        const setRemoveObject = newContents.splice(seoIndex, 1)[0];
        sortContents = [...sortContents, setRemoveObject];
      }
      if (hasInsertCategory) {
        newContents = [
          ...newContents,
          {
            type: CmsType.isCategoryPage,
            detail: null,
            skus: [],
            color: null,
            colorSecond: null,
            keyword: null,
            isSlide: false,
            title: null,
          },
          ...sortContents,
        ];
      } else {
        newContents = [...newContents, ...sortContents];
      }
    } else {
      let seoIndex = newContents?.findIndex(
        (item) => item.type === CmsType.seoLandingPage,
      );
      if (seoIndex !== -1) {
        const setRemoveObject = newContents.splice(seoIndex, 1)[0];
        sortContents = [...sortContents, setRemoveObject];
      }
      newContents = [...newContents, ...sortContents];
    }
  }

  return (
    <ActionWrapper>
      <div className="px-0">
        {newContents.length > 0 ? (
          <>
            {newContents.map((e, i) => (
              <div key={i} className="my-3">
                {e.type === CmsType.isCategoryPage ? (
                  children
                ) : (
                  <CMSContent content={e} />
                )}
              </div>
            ))}
            {!newContents.some((e) => e.type == CmsType.isCategoryPage) &&
              children}
          </>
        ) : (
          <>
            {section1 ? (
              <div
                className="mb-2"
                dangerouslySetInnerHTML={{
                  __html:
                    section1
                      ?.replaceAll('d-flex', 'flex')
                      ?.replaceAll(
                        'justify-content-center',
                        'justify-center',
                      ) ?? '',
                }}
              />
            ) : (
              <></>
            )}
            {children}
          </>
        )}
      </div>
    </ActionWrapper>
  );
}

const CMSContent = ({ content }: { content: Content }) => {
  return (
    <>
      {content.type === CmsType.heroBanner &&
        content.detailBanner &&
        content.detailBanner.length > 0 && (
          <div className="my-1">
            <HeroBanner
              name={content.title ?? 'cms'}
              banners={content.detailBanner ?? undefined}
            />
          </div>
        )}
      {[CmsType.textDesc, CmsType.article].includes(content.type) && (
        <div
          className="leading-5"
          dangerouslySetInnerHTML={{ __html: content.detailString || '' }}
        />
      )}
      {content.type === CmsType.articleCat && content.detailArticle && (
        <ArticleCat content={content.detailArticle} />
      )}
      {content.type === CmsType.seoLandingPage && (
        <SeoCMS content={content.detailString ?? undefined} />
      )}
      {content.type === CmsType.default &&
        (content && content.productskus && content.productskus.length > 0 ? (
          <>
            <DefaultContent data={content} />
            <Default data={content} />
          </>
        ) : (
          <DefaultContent data={content} />
        ))}
      {content.type === CmsType.flashsale && content.detailFlashSale && (
        <FlashSale
          flashsale={content.detailFlashSale}
          products={content.productskus}
        />
      )}
      {content.type === CmsType.issueCoupon && content && (
        <UseCoupon title={content.title} />
      )}
      {content.type === CmsType.bundleimage && content.detailBundleImage && (
        <BundleImage
          data={content.detailBundleImage}
          isSlide={content.isSlide}
        />
      )}
      {content && content.type === CmsType.sku && content.skuProductskus && (
        <SkuProductskus
          data={content.skuProductskus}
          isSlide={content.isSlide}
          keyproduct={content.keyproduct}
          title={
            content.title && content.title.startsWith('H-') ? '' : content.title
          }
        />
      )}
      {content && content.detailBundle && content.type === CmsType.bundle && (
        <BundlePage data={content.detailBundle} isFromCms={true} />
      )}
      {content && content.detailCoupon && content.type === CmsType.coupon && (
        <Coupon data={content} />
      )}
    </>
  );
};

export const ActionWrapper = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const get1010CouponResults = useSelector(get1010CouponResultsSelector);
  const getTypeCouponResults = useSelector(getTypeCouponResultsSelector);
  const isLoading1010Coupon = useSelector(isLoading1010CouponSelector);
  const isClickGet1010coupon = useSelector(isClickGet1010couponSelector);
  const { t, i18n } = useTranslation();
  const { t: t_product } = useTranslation('product');
  const router = useRouter();
  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        event.target instanceof HTMLElement &&
        event.target.getAttribute('twd-click') !== null
      ) {
        const twdClickAttribute = event.target.getAttribute('twd-click');
        if (twdClickAttribute) {
          event.preventDefault();
          if (twdClickAttribute.includes('clickTopMenu')) {
            const regex = /'([^']+)'/;
            const match = twdClickAttribute.match(regex);
            if (match) {
              const extractedUrl = match[1];
              router.push(extractedUrl);
            }
          }
        }
      } else {
        const target = event.target;
        const parentElement = target.parentElement.parentElement;
        const topParentElement = target.parentElement;
        let finalParentElement = null;
        if (
          parentElement.classList.contains('btn') ||
          parentElement.hasAttribute('twd-click')
        ) {
          finalParentElement = parentElement;
        } else if (
          topParentElement.classList.contains('btn') ||
          topParentElement.hasAttribute('twd-click')
        ) {
          finalParentElement = topParentElement;
        }
        if (finalParentElement) {
          const twdClickAttribute =
            finalParentElement.getAttribute('twd-click');
          if (twdClickAttribute) {
            event.preventDefault();
            if (twdClickAttribute.includes('get1010coupon')) {
              dispatch(setIsClick(true));
              const regex = /\((\d+)\)/;
              const match = twdClickAttribute.match(regex);
              const extractedNumber = match ? match[1] : '';
              dispatch(
                get1010CouponQuery({
                  lang: i18n.language,
                  rewardid: extractedNumber,
                }),
              );
            } else if (twdClickAttribute.includes('getTypeCoupon')) {
              let campaignId = '';
              let campaignType = '';
              dispatch(setIsClick(true));
              const regex = /'([^']+)'/;
              const match = twdClickAttribute.match(regex);
              if (match) {
                const extractedNumber = match[1];
                campaignId = extractedNumber;
              }
              const regexType = /,\s*'([^']+)'/;
              const matchType = twdClickAttribute.match(regexType);
              if (matchType) {
                const extractedType = matchType[1];
                campaignType = extractedType;
              }
              if (campaignType.toString().toLocaleLowerCase() === 'ncpn') {
                dispatch(
                  getTypeCouponQuery({
                    lang: i18n.language,
                    campaignId: campaignId,
                    type: 'ncpn',
                  }),
                );
              }
            } else {
              if (twdClickAttribute.includes('clickTopMenu')) {
                const regex = /'([^']+)'/;
                const match = twdClickAttribute.match(regex);
                if (match) {
                  const extractedUrl = match[1];
                  router.push(extractedUrl);
                }
              }
            }
          }
        }
      }
    };
    document
      .querySelector('.cms-wrapper')
      ?.addEventListener('click', handleClick);

    return () => {
      document
        .querySelector('.cms-wrapper')
        ?.removeEventListener('click', handleClick);
    };
  }, [router, dispatch, i18n.language]);

  useEffect(() => {
    if (!isLoading1010Coupon && isClickGet1010coupon) {
      if (
        get1010CouponResults.status === 401 ||
        getTypeCouponResults?.status === 401
      ) {
        showDialog
          .showMessageWithButton({
            text: t('pleaseloginbeforegetcode'),
            primaryButton: {
              name: t('signin'),
              callBack: () => {
                router.push(
                  `/${router.locale}/signin${getRedirectQuery(router.asPath)}`,
                );
              },
            },
            secondaryButton: {
              name: t('cancel'),
            },
          })
          .then(() => {
            dispatch(clearResultGet1010());
          });
      } else {
        if (
          get1010CouponResults.iserror == false ||
          getTypeCouponResults?.isError === false
        ) {
          showDialog
            .showMessageWithButton({
              text: t('congratulationsyouhavecoupon'),
              image: '/images/svg/success_circle.svg',
              primaryButton: {
                name: t('viewcoupon'),
                callBack: () => {
                  router.push(`/${router.locale}/profile/myvoucher`);
                },
              },
              secondaryButton: {
                name: t('close'),
              },
            })
            .then(() => {
              dispatch(clearResultGet1010());
            });
        } else {
          if (getTypeCouponResults) {
            let errorMessage: string | undefined =
              getTypeCouponResults.errorMessage;
            switch (getTypeCouponResults.errorCode) {
              case 'no_campaign_id':
                errorMessage = t('errorncpn:nocampaignid') ?? undefined;
                break;
              case 'invalid_type':
                errorMessage = t('errorncpn:invalidtype') ?? undefined;
                break;
              case 'limit_per_campaign_exceeded':
                errorMessage =
                  t('errorncpn:limitpercampaignexceeded') ?? undefined;
                break;
              case 'limit_per_user_exceeded':
                errorMessage = t('errorncpn:limitperuserexceeded') ?? undefined;
                break;
              case 'received_coupons_during_this_period':
                errorMessage =
                  t('errorncpn:receivedcouponsduringthisperiod') ?? undefined;
                break;
              case 'system_fail':
                errorMessage = t('errorncpn:systemfail') ?? undefined;
                break;
              case 'issue_coupon_failed':
                errorMessage = getTypeCouponResults.errorMessage;
                break;
              case 'campaign_id_not_found':
                errorMessage = t('errorncpn:campaignidnotfound') ?? undefined;
                break;
              case 'no_t1':
                errorMessage = t('errorncpn:not1') ?? undefined;
                break;
            }
            if (!errorMessage) {
              errorMessage = getTypeCouponResults.errorMessage;
            }
            showDialog
              .showErrorWithOkButton({
                text: `${errorMessage}`,
              })
              .then(() => {
                dispatch(clearResultGet1010());
              });
          } else {
            showDialog
              .showErrorWithOkButton({
                text: `${get1010CouponResults.msg}`,
              })
              .then(() => {
                dispatch(clearResultGet1010());
              });
          }
        }
      }
      dispatch(setIsClick(false));
    }
  }, [
    dispatch,
    isLoading1010Coupon,
    get1010CouponResults,
    router,
    t,
    t_product,
    isClickGet1010coupon,
    getTypeCouponResults,
  ]);
  return (
    <>
      {isLoading1010Coupon ? <Loading /> : ''}
      <div className="cms-wrapper">{children}</div>
    </>
  );
};
