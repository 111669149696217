import { ProductModel } from '@/models/Product.model';
import React, { useEffect, useRef, useState } from 'react';
import ProductCard from '../ProductCard';
import { Grid } from '@mui/material';
import Slider from 'react-slick';
import styles from '../../styles/slickProductCard.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper React components
import LeftArrow from '../widgets/arrow/LeftArrow';
import RightArrow from '../widgets/arrow/RightArrow';
import ProductSlideLoading from '../homepage/ProductSlide/Loading';
interface Props {
  data: { [key: string]: ProductModel[] };
  isSlide: boolean;
  keyproduct?: string[]; // Update the keyproduct type to string[]
  title: string | null;
}

const ThisLeftArrow = (props: any) => <LeftArrow {...props} />;
const ThisRightArrow = (props: any) => <RightArrow {...props} />;

// function SampleNextArrow(props: any) {
//   const { onClick } = props;
//   return (
//     <>
//       <Box
//         className={`bg-white shadow-lg cursor-pointer `}
//         sx={{
//           position: 'absolute',
//           zIndex: 1,
//           right: '-20px !important',
//           top: '40%',
//           transform: 'translateY(-50%)',
//         }}
//         onClick={onClick}
//       >
//         <Box
//           className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
//           sx={{ lineHeight: '1rem' }}
//         >
//           <i className="bi bi-chevron-right"></i>
//         </Box>
//       </Box>
//     </>
//   );
// }

// function SamplePrevArrow(props: any) {
//   const { onClick } = props;
//   return (
//     <>
//       <Box
//         className={`bg-white shadow-lg cursor-pointer`}
//         sx={{
//           position: 'absolute',
//           zIndex: 1,
//           top: '40%',
//           transform: 'translateY(-50%)',
//           left: '-20px !important',
//         }}
//         onClick={onClick}
//       >
//         <Box
//           className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
//           sx={{ lineHeight: '1rem' }}
//         >
//           <i className="bi bi-chevron-left"></i>
//         </Box>
//       </Box>
//     </>
//   );
// }
export default function SkuProductskus({
  data,
  isSlide,
  keyproduct,
  title,
}: Props) {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const sliderRef = useRef<Slider>(null);
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplaySpeed: 2000,
    arrows: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    prevArrow: <ThisLeftArrow />,
    nextArrow: <ThisRightArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          prevArrow: <ThisLeftArrow />,
          nextArrow: <ThisRightArrow />,
          dotsClass: `slick-dots ${styles.dots}`,
        },
      },
    ],
  };
  const showActive = (keyitem: string) => {
    setActiveCategory(keyitem);
  };
  // Show Verify modal
  useEffect(() => {
    if (keyproduct && keyproduct.length > 0) {
      showActive(`${keyproduct[0]}0`);
      setIsLoading(false);
    }
  }, [keyproduct]);
  return (
    <>
      {title ? <h2>{title}</h2> : <></>}
      {keyproduct && keyproduct.length > 0 ? (
        <Swiper
          spaceBetween={10}
          slidesPerView={'auto'}
          observer={true}
          observeParents={true}
          className="mb-2"
        >
          {keyproduct.map((product, index) => (
            <SwiperSlide key={index} style={{ width: 'auto' }}>
              <div
                className={
                  activeCategory === `${product}${index}`
                    ? 'hover:bg-primary px-2 mr-2 my-1 text-white border-2 border-primary bg-primary'
                    : 'hover:bg-primary px-2 mr-2 my-1 text-primary border-2 border-primary hover:text-white'
                }
                style={{ cursor: 'pointer', borderRadius: '5px' }}
                onClick={() => {
                  showActive(`${product}${index}`);
                }}
              >
                <span>{product}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <></>
      )}

      {Object.keys(data).map((category, index) => (
        <div
          key={category}
          data-keyitem={`${category}${index}`}
          className={`transition-opacity duration-300 ${
            activeCategory === `${category}${index}`
              ? 'opacity-100'
              : 'opacity-0 h-0 overflow-hidden'
          }`}
        >
          {isLoading ? (
            <ProductSlideLoading noBg noImage noTopic noPadding />
          ) : isSlide ? (
            <Slider
              ref={sliderRef}
              {...settings}
              infinite={data[category].length > 2}
            >
              {data[category].map((item) => (
                <div
                  key={`${category}${item.seq}`}
                  className={`${styles.navProd}`}
                >
                  <ProductCard product={item} />
                </div>
              ))}
            </Slider>
          ) : (
            <>
              <Grid container spacing={2}>
                {data[category].map((item) => (
                  <Grid key={`${category}${item.seq}`} item xs={6} lg={3}>
                    <ProductCard product={item} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
      ))}
    </>
  );
}
